import { render, staticRenderFns } from "./EvidenceStep.vue?vue&type=template&id=5c8926f6"
import script from "./EvidenceStep.vue?vue&type=script&lang=js"
export * from "./EvidenceStep.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/staging-5kjih/staging-create.mouse.org/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5c8926f6')) {
      api.createRecord('5c8926f6', component.options)
    } else {
      api.reload('5c8926f6', component.options)
    }
    module.hot.accept("./EvidenceStep.vue?vue&type=template&id=5c8926f6", function () {
      api.rerender('5c8926f6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/projects/steps/EvidenceStep.vue"
export default component.exports