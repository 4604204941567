<template>
  <tabs
    :nav-tabs="tabs"
    :options="tabOptions"
  />
</template>

<script>

import Tabs from '../tabs/Tabs.vue';

export default {
  name: 'ProjectShowTabs',
  components: { Tabs },
  props: {},
  data() {
    return {
      tabs: [
        {
          title: 'Plan',
          component: 'project-plan',
          active: (this.$route.name === 'project-plan'),
          visible: false,
          checkOwnership: true,
        },
        {
          title: 'Steps',
          component: 'project-steps',
          active: (this.$route.name === 'project-steps'),
          visible: false,
          checkOwnership: true,
        },
        {
          title: 'Submit Work',
          component: 'project-add-work',
          active: (this.$route.name === 'project-add-work'),
          visible: false,
          checkOwnership: true,
        },
      ],
      tabOptions: {
        groupId: this.groupId,
      },
      isLoading: true,
      owner: false,
    };
  },
  created() {
    bus.$on('disable:tabs', this.removeTabs);
    bus.$on('enable:tabs', this.activateAllTabs);
  },
  mounted() {},
  methods: {
    removeTabs() {
      this.tabs.forEach((tab) => {
        tab.visible = false;
      });
    },
    activateAllTabs() {
      this.tabs.forEach((tab) => {
        tab.visible = true;
      });
    },
  },
};
</script>
