export const SET_CURRENT_MODAL_INDEX = 'SET_CURRENT_MODAL_INDEX';
export const SET_EVIDENCE_ID_ARRAY = 'SET_EVIDENCE_ID_ARRAY';

// initial state
const state = {
  currentModalIndex: null,
  evidenceIdArray: [],
};

// actions
const actions = {
  setcurrentModalIndex: ({ commit }, currentModalIndex) => {
    commit(SET_CURRENT_MODAL_INDEX, currentModalIndex);
  },
  setEvidenceIdArray: ({ commit }, evidenceIdArray) => {
    commit(SET_EVIDENCE_ID_ARRAY, evidenceIdArray);
  },
  // clearCurrentModalIndex
};

// mutations
const mutations = {
    SET_CURRENT_MODAL_INDEX: (state, payload) => {
        state.currentModalIndex = payload;
    },

    SET_EVIDENCE_ID_ARRAY: (state, payload) => {
        state.evidenceIdArray = payload;
    },
};

export default {
  // namespaced: true,
  state,
  actions,
  mutations,
};
