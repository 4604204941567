<template>
  <GroupWorkLayout :group="group">
    <GroupWorkFilterLayout
      :clear-filter="clearFilters"
      :is-filter-selected="isFilterSelected"
    >
      <div class="sr-only">
        Project Name
      </div>
      <ProjectSelect
        v-model="form.projectId"
        :projects="searchFilters.projects"
      />
      <div class="sr-only">
        Member Name
      </div>
      <UserSelect
        v-model="form.userId"
        :users="searchFilters.users"
      />
      <div class="sr-only">
        Review Status
      </div>
      <StatusSelect
        v-model="form.statusId"
        :statuses="searchFilters.statuses"
      />
      <PageSelect
        v-model="form.perPage"
        :pages="pages"
      />
    </GroupWorkFilterLayout>
    <div class="group-work-table">
      <table class="table__paginated">
        <thead>
          <tr>
            <th>
              <GroupWorkTableSort
                field="date"
                v-bind="{ modifySort, sortDir: form.sortDir, sortBy: form.sortBy }"
              >
                Date
              </GroupWorkTableSort>
            </th>
            <th>
              <GroupWorkTableSort
                field="project_name"
                v-bind="{ modifySort, sortDir: form.sortDir, sortBy: form.sortBy }"
              >
                Project Name
              </GroupWorkTableSort>
            </th>
            <th>
              <GroupWorkTableSort
                field="submitted_by"
                v-bind="{ modifySort, sortDir: form.sortDir, sortBy: form.sortBy }"
              >
                Submitted By
              </GroupWorkTableSort>
            </th>
            <th>Collaborators</th>
            <th>
              <GroupWorkTableSort
                field="comments"
                v-bind="{ modifySort, sortDir: form.sortDir, sortBy: form.sortBy }"
              >
                Comments
              </GroupWorkTableSort>
            </th>
            <th>
              <GroupWorkTableSort
                field="reviewed"
                v-bind="{ modifySort, sortDir: form.sortDir, sortBy: form.sortBy }"
              >
                Reviewed
              </GroupWorkTableSort>
            </th>
          </tr>
        </thead>
        <tbody>
          <GroupWorkTableRow
            v-for="(evidence, index) in results.evidence"
            :key="evidence.id"
            :current-index="index"
            :reload-fn="fetchGroupWork"
            :evidence="evidence"
          />
          <tr v-if="results.evidence.length === 0 && !results.loading">
            <td colspan="6">
              <div style="text-align: center;">
                No results found for this group with these filters
              </div>
            </td>
          </tr>
          <tr v-if="results.loading && results.evidence.length < 1">
            <td colspan="6">
              <div style="text-align: center">
                <h2>Group work loading now...</h2>
                <p>
                  <img
                    src="/img/gear-animation-115.gif"
                    alt="Group work loading"
                  >
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <GroupWorkPagination
      v-model="paginator.page"
      :last-page="paginator.lastPage"
    />
  </GroupWorkLayout>
</template>

<script>
import _ from 'lodash';
import GroupWorkLayout from './group-work/GroupWorkLayout.vue';
import ProjectSelect from './group-work/ProjectSelect.vue';
import UserSelect from './group-work/UserSelect.vue';
import PageSelect from './group-work/PageSelect.vue';
import GroupWorkFilterLayout from './group-work/GroupWorkFilterLayout.vue';
import StatusSelect from './group-work/StatusSelect.vue';
import GroupWorkTableRow from './group-work/table/GroupWorkTableRow.vue';
import GroupWorkTableSort from './group-work/table/GroupWorkTableSort.vue';
import GroupWorkPagination from './group-work/GroupWorkPagination.vue';

export default {
  name: 'GroupWork',
  components: {
    GroupWorkPagination, GroupWorkTableSort, GroupWorkTableRow, StatusSelect, GroupWorkFilterLayout, UserSelect, ProjectSelect, GroupWorkLayout, PageSelect
  },
  data() {
    return {
      searchFilters: {
        loaded: false,
        users: [],
        projects: [],
        statuses: [],
      },
      pages: [10, 25, 50, 75],
      form: {
        userId: '',
        projectId: null,
        statusId: null,
        perPage: 10,
        sortBy: 'date',
        sortDir: 'desc',
      },
      paginator: {
        page: 1,
        lastPage: 1,
      },
      results: {
        loading: false,
        evidence: [],
      },
    };
  },
  computed: {
    getEvidenceRoute() {
      return laroute.route('api.evidences.group', { group: this.groupId });
    },
    getFiltersRoute() {
      return laroute.route('api.evidences.group.filters', { group: this.groupId });
    },
    group() {
      return this.$store.state.groups.active;
    },
    groupId() {
      return this.$route.params.id ? this.$route.params.id : this.group.id;
    },
    isFilterSelected() {
      return !!this.form.statusId || !!this.form.userId || !!this.form.projectId;
    },
    evidenceIdArray() {
      return _.map(this.results.evidence, 'id');
    }
  },
  watch: {
    form: {
      handler() {
        this.changeFilter();
      },
      deep: true,
    },
    'paginator.page': function watchPaginatorPage() {
      this.fetchGroupWork();
    },
    'results.evidence': function() {
      this.setEvidenceIdArray()
    }
  },
  mounted() {
    this.fetchSearchFilters();
    this.fetchGroupWork();
    if (this.$route.name !== 'group-work') {
      this.$router.push({ name: 'group-work', params: { id: `${this.groupId}` } }).catch();
    }
  },
  methods: {
    async fetchSearchFilters() {
      const res = await axios.get(this.getFiltersRoute).catch((r) => r);
      const { projects, users, statuses } = res.data;
      this.searchFilters = { projects, users, statuses };
    },
    async fetchGroupWork() {
      if (this.results.loading) {
        return;
      }

      this.results.loading = true;

      const params = { ...this.form, page: this.paginator.page };
      const res = await axios.get(this.getEvidenceRoute, { params }).catch((r) => r);

      this.results.evidence = res.data.evidence.data;
      this.$store.dispatch('setCurrentEvidences', res.data.evidence.data);

      this.paginator.lastPage = res.data.evidence.last_page;
      this.paginator.page = res.data.evidence.current_page;
      this.results.loading = false;
    },
    changeFilter() {
      this.paginator.page = 1;
      this.fetchGroupWork();
    },
    modifySort(sortBy, sortDir) {
      this.form = { ...this.form, sortBy, sortDir };
    },
    clearFilters() {
      this.form = {
        ...this.form, statusId: null, userId: null, projectId: null,
      };
    },
    setEvidenceIdArray() {
      let evidenceArray = _.map(this.results.evidence, 'id')
      this.$store.dispatch('setEvidenceIdArray', evidenceArray);
    }
  },
};
</script>