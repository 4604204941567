import { render, staticRenderFns } from "./GroupWorkTableSort.vue?vue&type=template&id=71e17d7d&scoped=true"
import script from "./GroupWorkTableSort.vue?vue&type=script&lang=js"
export * from "./GroupWorkTableSort.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71e17d7d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/staging-5kjih/staging-create.mouse.org/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('71e17d7d')) {
      api.createRecord('71e17d7d', component.options)
    } else {
      api.reload('71e17d7d', component.options)
    }
    module.hot.accept("./GroupWorkTableSort.vue?vue&type=template&id=71e17d7d&scoped=true", function () {
      api.rerender('71e17d7d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/groups/group-work/table/GroupWorkTableSort.vue"
export default component.exports