import Home from './components/home/Home.vue';
import Login from './components/auth/Login.vue';
import Registration from './components/auth/Registration.vue';
import GroupSwitch from './components/groups/GroupSwitch.vue';

import PageNotFound from './components/global/PageNotFound.vue';

// Group Tabs
import GroupTabs from './components/groups/GroupTabs.vue';
import GroupNotifications from './components/groups/GroupNotifications.vue';
import Playlist from './components/groups/Playlist.vue';
import ManagePlaylist from './components/groups/ManagePlaylist.vue';
import GroupWork from './components/groups/GroupWork.vue';
import Badges from './components/groups/Badges.vue';
import Members from './components/groups/Members.vue';
import MembersArchived from './components/groups/MembersArchived.vue';
import GroupEdit from './components/groups/GroupEdit.vue';
import JoinGroup from './components/groups/JoinGroup.vue';
import CreateGroup from './components/groups/CreateGroup.vue';

// Join components
import JoinSite from './components/site/JoinSite.vue';

// Project finder tabs
import ProjectTabs from './components/projects/ProjectTabs.vue';
import ProjectFinder from './components/projects/ProjectFinder.vue';

// Project tabs
import ProjectShowTabs from './components/projects/ProjectShowTabs.vue';
import ProjectPlan from './components/projects/ProjectPlan.vue';
import ProjectSteps from './components/projects/ProjectSteps.vue';
import ProjectAddWork from './components/projects/ProjectAddWork.vue';
import CourseModule from './components/courses/CourseModule.vue';

// Profile
import ProfileTabs from './components/profile/ProfileTabs.vue';
import ProfileView from './components/profile/ProfileView.vue';
import ProfileEdit from './components/profile/ProfileEdit.vue';
import ProfileEducatorEdit from './components/profile/ProfileEducatorEdit.vue';
import ProfileWork from './components/work/Works.vue';

// Notifications
import Notifications from './components/notifications/Notifications.vue';

// Badge evidence
import BadgeEvidence from './components/badges/BadgeEvidence.vue';

export default [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/register',
    name: 'register',
    component: Registration,
  },
  {
    path: '/group/switch',
    name: 'group-switch',
    component: GroupSwitch,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/group/:id',
    component: GroupTabs,
    children: [
      {
        path: '',
        name: 'group-playlist',
        component: Playlist,
      },
      {
        path: 'group-work',
        name: 'group-work',
        component: GroupWork,
      },
      {
        path: 'badges',
        name: 'group-badges',
        component: Badges,
      },
      {
        path: 'members',
        name: 'group-members',
        component: Members,
      },
      {
        path: 'manage-members/archived',
        name: 'group-members-archived',
        component: MembersArchived,
      },
      {
        path: 'group-edit',
        name: 'group-edit',
        component: GroupEdit,
      },
      {
        path: 'notifications',
        name: 'group-notifications',
        component: GroupNotifications,
      },
      {
        path: 'manage-playlist',
        name: 'manage-playlist',
        component: ManagePlaylist,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/projects',
    component: ProjectTabs,
    children: [
      {
        path: '',
        name: 'project-finder',
        component: ProjectFinder,
      },
      {
        path: 'courses/:slug',
        name: 'course-module',
        component: CourseModule,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/project/:id',
    name: 'project-show-tabs',
    component: ProjectShowTabs,
    children: [
      {
        path: 'step/:step(\\d+)+',
        name: 'project-steps',
        component: ProjectSteps,
      },
      {
        path: 'plan/:plan(\\d+)+',
        name: 'project-plan',
        component: ProjectPlan,
      },
      {
        path: 'submit',
        name: 'project-add-work',
        component: ProjectAddWork,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/profile/:id',
    component: ProfileTabs,
    children: [
      {
        path: '',
        name: 'profile-view',
        component: ProfileView,
      },
      {
        path: 'my-work',
        name: 'profile-work',
        component: ProfileWork,
      },
      {
        path: 'settings',
        name: 'profile-settings',
        component: ProfileEdit,
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/profile/edit/:id',
    name: 'profile-educator-edit',
    component: ProfileEducatorEdit,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: Notifications,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/join/group',
    name: 'join-group',
    component: JoinGroup,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/create-group',
    name: 'create-group',
    component: CreateGroup,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/join/site',
    name: 'join-site',
    component: JoinSite,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/badge-evidence/:id',
    name: 'badge-evidence',
    component: BadgeEvidence,
  },
  {
    path: '/project/:id/*',
    name: 'project-not-found',
    component: PageNotFound,
  },
  {
    path: '/admin/*',
    name: 'admin',
  },
  {
    path: '*',
    name: 'page-not-found',
    component: PageNotFound,
  },
];
