<template>
  <div
    v-if="!isLoggedIn"
    class="page-wrap"
  >
    <main>
      <router-link
        to="/"
        class="back-to__button"
      >
        <span class="back-to__icon">
          <svg
            width="9px"
            height="17px"
            viewBox="0 0 9 17"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Shape</title>
            <desc>Created with Sketch.</desc>
            <g
              id="Page-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Project-Gallery-Browser---med/tablet"
                transform="translate(-23.000000, -204.000000)"
                fill="#00A886"
              >
                <g
                  id="back-to-profile-copy"
                  transform="translate(23.000000, 203.000000)"
                >
                  <path
                    id="Shape"
                    d="M12.4801154,11.237282 L5.5086642,5.27180751 C5.22997992,5.04574032 4.90456303,4.93270673 4.53280847,4.93270673 C4.15381364,4.93270673 3.83208271,5.04574032 3.56748402,5.27180751 L-3.41463012,11.237282 C-3.68633743,11.4693101 -3.82219108,11.747339 -3.82219108,12.0711437 C-3.82219108,12.4010219 -3.68620578,12.6759016 -3.41463012,12.8957828 L-2.61030279,13.5829821 C-2.35281273,13.8151227 -2.0310818,13.9313055 -1.64497835,13.9313055 C-1.2588749,13.9313055 -0.937143967,13.8150103 -0.679653907,13.5829821 L4.53280847,9.13868114 L9.74527084,13.5828696 C10.0026293,13.8148978 10.3243602,13.9311931 10.7107269,13.9311931 C11.0894585,13.9311931 11.415007,13.8148978 11.6865827,13.5828696 L12.4907783,12.8956704 C12.755377,12.6696032 12.887808,12.3947235 12.887808,12.0710313 C12.887808,11.7472266 12.7519544,11.4691977 12.4801154,11.237282 L12.4801154,11.237282 Z"
                    transform="translate(4.532808, 9.432006) rotate(-90.000000) translate(-4.532808, -9.432006) "
                  />
                </g>
              </g>
            </g>
          </svg>
        </span>
        <span class="back-to__text">Back to Home</span>
      </router-link>

      <section class="primary-content">
        <div class="content-box">
          <h1 class="page-title">
            Login
          </h1>

          <div class="login-welcome">
            <p class="instructional">
              Welcome to Mouse Create! <span class="sm-block">Login below or Sign up here:</span>
            </p>
            <div class="login-buttons">
              <router-link
                v-if="!isLoggedIn"
                :to="{ name: 'register' }"
                class="button__secondary"
              >
                Create <span class="sm-hidden">a </span>new account
              </router-link>
              <span class="spacing">or</span>
              <button
                v-if="!isLoggedIn"
                class="button__secondary"
                @click.prevent="registerWithGoogle"
              >
                Join with <span class="sm-hidden">a </span>Google<span class="sm-hidden"> account</span>
              </button>
            </div>
          </div>

          <div class="login-grid">
            <div class="login-grid-login">
              <form
                class="form-horizontal"
                method="POST"
              >
                <div class="log-in-form">
                  <div class="form-row short-row">
                    <p>Login</p>
                    <label
                      for="username"
                      class="visuallyhidden"
                    >Username or Email</label>
                    <input
                      id="username"
                      v-model="form.username"
                      v-validate="'required'"
                      type="text"
                      name="username"
                      placeholder="Username or Email"
                    >

                    <div
                      v-if="errors.has('username')"
                      class="form-error"
                    >
                      <span><strong>{{ errors.first('username') }}</strong></span>
                    </div>
                    <div
                      v-else
                      class="form-error"
                    >
                      <span><strong>{{ errors.first('email') }}</strong></span>
                    </div>
                  </div>

                  <div class="form-row short-row">
                    <label
                      for="password"
                      class="visuallyhidden"
                    >Username</label>
                    <input
                      id="password"
                      v-model="form.password"
                      v-validate="'required'"
                      type="password"
                      name="password"
                      placeholder="Password"
                    >

                    <div
                      v-show="errors.has('password')"
                      class="form-error"
                    >
                      <span><strong>{{ errors.first('password') }}</strong></span>
                    </div>
                  </div>
                  <div class="form-row short-row">
                    <button
                      type="submit"
                      class="button expanded"
                      @click.stop.prevent="login"
                    >
                      Log In
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="login-grid-google">
              <p>Or, Log in with your Google or G Suite Account.</p>
              <button
                type="submit"
                class="button"
                @click.stop.prevent="loginWithGoogle"
              >
                <span class="text">Log In With Google</span>
                <span class="icon"><img
                  src="img/icon-google.png"
                  alt="Google"
                ></span>
              </button>
            </div>
          </div>
          <p class="text-center">
            <a href="/password/reset">Forgot your username or password?</a>
          </p>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data: () => ({
    form: {
      username: '',
      password: '',
    },
  }),
  computed: {
    currentUser() {
      return (this.$store.state.users.current) ? this.$store.state.users.current : null;
    },
    isLoggedIn() {
      return !!this.currentUser;
    },
  },
  mounted() {
    document.body.className += ' single-column';

    if (this.$route.query.emailexist) {
      const message = "Oops! Seems you already have a Create account with this email address. Please login directly to continue. If you've never logged in using Google before, you can set it up from your Profile page.";

      this.$store.dispatch('loadMessage', {
        content: message,
        type: 'error',
        autoclose: true,
      });
    } else if (this.$route.query.invalid) {
      this.errors.add({
        field: 'username',
        msg: 'These credentials do not match our records.',
      });
    } else {
      this.onLoadRoute();
    }
  },
  methods: {
    loginWithGoogle() {
      const self = this;
      axios.get('/sanctum/csrf-cookie').then(() => {
        axios.get(laroute.route('oauth.google.login.url')).then((response) => {
          if (response.data.route) {
            const { groupId } = response.data;
            self.$store.dispatch('getMe').then(() => {
              if (groupId) {
                self.$store.dispatch('setActiveGroup', groupId);
              }
              self.$router.push(response.data.route).catch(() => {});
            });
          } else {
            window.location = response.data;
          }
        });
      }).catch(() => {});
    },
    registerWithGoogle() {
      axios.get(laroute.route('oauth.google.register.url')).then((response) => {
        window.location = response.data;
      });
    },
    login() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }

        axios.get('/sanctum/csrf-cookie').then(() => {
          axios.post(laroute.route('auth.login'), this.form).then(() => {
            this.$store.dispatch('getMe').then(() => {
              axios.get(laroute.route('switchboard'))
                .then((response) => {
                  if (response.data.groupId) {
                    this.$store.dispatch('setActiveGroup', response.data.groupId);
                  }

                  this.$router.push(response.data.route).catch(() => {});
                });
            });
          }).catch((error) => {
            const message = (error.response.data.errors.username) ? error.response.data.errors.username : error.response.data.errors.email;

            this.errors.add({
              field: 'username',
              msg: message[0],
            });
          });
        }).catch(() => {});
      });
    },
    onLoadRoute() {
      axios.get(laroute.route('switchboard'))
        .then((response) => {
          if (response.data.groupId) {
            this.$store.dispatch('setActiveGroup', response.data.groupId);
          }

          this.$router.push(response.data.route).catch(() => {});
        });
    },
  },
};
</script>
