<template>
  <div class="project-tab">
    <h1>{{ project.title }}</h1>

    <div
      v-if="!isLoading && project.hasPackAccess"
      class="project__content"
    >
      <template v-for="plan in plans">
        <div
          v-if="parseInt(currentStep) === parseInt(plan.start.stepOrder)"
          :key="plan.start.stepOrder"
          class="steps__content">

            <div class="project__step-header">
              <a
                class="step-pagination step-pagination__previous"
                href="#"
                :class="{'step-pagination--hidden': isFirstPage}"
                @click.stop.prevent="previous"
              >
                <prev-page-icon />
              </a>

              <div class="step-header__title">
                {{ plan.start.title }}
              </div>

              <a
                class="step-pagination step-pagination__next"
                href="#"
                :class="{'step-pagination--hidden': isLastPage}"
                @click.stop.prevent="next"
              >
                <next-page-icon />
              </a>
            </div>

            <evidence-step
              v-for="(stepContent, index) in plan.content"
              :key="index"
              :index="index"
              :step="stepContent"
            />

            <!-- <evidence-step :step="plan" /> -->
        </div>
      </template>
    </div>

    <div class="project__steps-nav steps__nav-desktop">
      <ol class="steps">
        <li
          v-for="(plan, o) in plans"
          :key="o"
        >
          <router-link :to="{ name: 'project-plan', params: { id: $route.params.id, plan: plan.start.stepOrder }}">
            <span class="step">{{ plan.start.stepOrder }}</span>
            <span>{{ plan.start.title }}</span>
          </router-link>
        </li>
      </ol>
    </div>

    <div v-show="!isLoading && !project.hasPackAccess">
      <p>You do not have access to this project</p>
    </div>
  </div>
</template>

<script>

import EvidenceStep from './steps/EvidenceStep.vue';
import NextPageIcon from './icons/NextPageIcon.vue';
import PrevPageIcon from './icons/PrevPageIcon.vue';

export default {
  name: 'ProjectPlan',
  components: {
    EvidenceStep,
    NextPageIcon,
    PrevPageIcon
  },
  props: {},
  data() {
    return {
      project: {
        title: '',
      },
      plans: {},
      pageTitle: 'Mouse Create',
      isLoading: true,
      currentStep: '1'
    };
  },
  metaInfo() {
    return {
      title: this.getTitle(),
    };
  },
  computed: {
    getShowPlanRoute() {
      return laroute.route('api.projects.show.plan', {
        project: this.$route.params.id,
      });
    },
    getProjectRoute() {
      return laroute.route('api.projects.show', {
        project: this.$route.params.id,
      });
    },
    isFirstPage() {
      return parseInt(this.currentStep, 10) === 1;
    },
    isLastPage() {
      return parseInt(this.currentStep, 10) === this.totalSteps;
    },
    totalSteps() {
      return Object.keys(this.plans).length;
    },
  },
  mounted() {
    this.getShowPlan();
    this.getProject();

    this.currentStep = (this.$route.params.plan) ? this.$route.params.plan : 1;

    console.log(this.$route)
    console.log(this.currentStep)

    if (this.$route.name !== 'project-plan') {
      this.$router.push({ name: 'project-plan',
        params: {
          id: this.$route.params.id,
          plan: `${this.currentStep}`,
        },
      });
    }
  },
  methods: {
    getShowPlan() {
      axios.get(this.getShowPlanRoute)
        .then((response) => {
          this.plans = response.data.data;
          bus.$emit('enable:tabs');
        });
    },
    getProject() {
      axios.get(this.getProjectRoute)
        .then((response) => {
          this.project = response.data;
          this.pageTitle = this.project.title;
          this.isLoading = false;
        });
    },
    getTitle() {
      return `${this.pageTitle} - Plan`;
    },
    next() {
      this.currentStep = parseInt(this.currentStep, 10) + 1;
      this.updateRoute();
    },
    previous() {
      this.currentStep -= 1;
      this.updateRoute();
    },
    updateRoute() {
      this.$router.push({
        name: 'project-plan',
        params: {
          id: this.$route.params.id,
          plan: `${this.currentStep}`,
        },
      });
    },
  },
};
</script>
