var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "project-tab" }, [
    _c("h1", [_vm._v(_vm._s(_vm.project.title))]),
    _vm._v(" "),
    !_vm.isLoading && _vm.project.hasPackAccess
      ? _c(
          "div",
          { staticClass: "project__content" },
          [
            _vm._l(_vm.plans, function (plan) {
              return [
                parseInt(_vm.currentStep) === parseInt(plan.start.stepOrder)
                  ? _c(
                      "div",
                      {
                        key: plan.start.stepOrder,
                        staticClass: "steps__content",
                      },
                      [
                        _c("div", { staticClass: "project__step-header" }, [
                          _c(
                            "a",
                            {
                              staticClass:
                                "step-pagination step-pagination__previous",
                              class: {
                                "step-pagination--hidden": _vm.isFirstPage,
                              },
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.previous($event)
                                },
                              },
                            },
                            [_c("prev-page-icon")],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "step-header__title" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(plan.start.title) +
                                "\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "step-pagination step-pagination__next",
                              class: {
                                "step-pagination--hidden": _vm.isLastPage,
                              },
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.next($event)
                                },
                              },
                            },
                            [_c("next-page-icon")],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(plan.content, function (stepContent, index) {
                          return _c("evidence-step", {
                            key: index,
                            attrs: { index: index, step: stepContent },
                          })
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            }),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "project__steps-nav steps__nav-desktop" }, [
      _c(
        "ol",
        { staticClass: "steps" },
        _vm._l(_vm.plans, function (plan, o) {
          return _c(
            "li",
            { key: o },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "project-plan",
                      params: {
                        id: _vm.$route.params.id,
                        plan: plan.start.stepOrder,
                      },
                    },
                  },
                },
                [
                  _c("span", { staticClass: "step" }, [
                    _vm._v(_vm._s(plan.start.stepOrder)),
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(plan.start.title))]),
                ]
              ),
            ],
            1
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoading && !_vm.project.hasPackAccess,
            expression: "!isLoading && !project.hasPackAccess",
          },
        ],
      },
      [_c("p", [_vm._v("You do not have access to this project")])]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }