import { render, staticRenderFns } from "./Badges.vue?vue&type=template&id=0bca77a0"
import script from "./Badges.vue?vue&type=script&lang=js"
export * from "./Badges.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/staging-5kjih/staging-create.mouse.org/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0bca77a0')) {
      api.createRecord('0bca77a0', component.options)
    } else {
      api.reload('0bca77a0', component.options)
    }
    module.hot.accept("./Badges.vue?vue&type=template&id=0bca77a0", function () {
      api.rerender('0bca77a0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/groups/Badges.vue"
export default component.exports