import { render, staticRenderFns } from "./CourseRelatedProjects.vue?vue&type=template&id=848c6d90"
import script from "./CourseRelatedProjects.vue?vue&type=script&lang=js"
export * from "./CourseRelatedProjects.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/staging-5kjih/staging-create.mouse.org/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('848c6d90')) {
      api.createRecord('848c6d90', component.options)
    } else {
      api.reload('848c6d90', component.options)
    }
    module.hot.accept("./CourseRelatedProjects.vue?vue&type=template&id=848c6d90", function () {
      api.rerender('848c6d90', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/courses/CourseRelatedProjects.vue"
export default component.exports