<template>
  <tr>
    <td
      data-label="Submitted"
      class="table__cell__valign-top"
    >
      {{ evidence.submitted_at | formatDate('M/D/YY') }}
    </td>
    <td data-label="Project Name">
      <a
        href="#"
        @click="viewEvidenceModal"
      >
        <div class="icon__group__icon-top-small">
          <ProjectIcon />
        </div>
        <div class="icon__group__text-top-small">
          {{ evidence.project.title }}
        </div>
      </a>
    </td>
    <td data-label="Created by">
      <router-link :to="`/profile/${evidence.user.token}`">
        <div class="icon__group">
          <div
            class="profile__pic-member"
            v-html="evidence.user.avatar"
          />
          <div class="icon__group__text">
            {{ evidence.user.profile ? evidence.user.profile.full_name : '' }}
            <span v-if="evidence.membership && evidence.membership.status === 'archived'">
              (Archived)
            </span>
          </div>
        </div>
      </router-link>
    </td>
    <td data-label="Collaborators">
      <div class="icon__group collaborators">
        <div class="icon__group__icon">
          <span
            v-for="collaborator in evidence.collaborators.slice(0, 3)"
            :key="collaborator.id"
            class="profile__pic-member"
          >
            <router-link
              :to="`/profile/${collaborator.token}`"
              v-html="collaborator.avatar"
            />
          </span>
        </div>
        <div
          v-if="evidence.collaborators.length > 3"
          class="icon__group__text"
        >
          +<span>{{ evidence.collaborators.length - 3 }}</span>
        </div>
      </div>
    </td>
    <td data-label="Comments">
      <div class="icon__group">
        <a
          href="#"
          @click="launchComments"
        >
          <div class="icon__group__icon">
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              xmlns="http://www.w3.org/2000/svg"
            ><title>Comment</title><path
              d="M2.564 23.7c1.42-.13 2.81-.492 4.115-1.073l.52-.233.52.243c1.496.704 3.13 1.067 4.788 1.064 6.21-.003 11.24-5.027 11.242-11.222 0-6.2-5.035-11.227-11.248-11.228-6.214 0-11.25 5.025-11.252 11.224 0 2.566.88 5.055 2.495 7.05l.414.512-.188.63c-.32 1.078-.796 2.1-1.408 3.033z"
              stroke="#00A886"
              stroke-width="2.5"
              fill="none"
              fill-rule="evenodd"
            /></svg>
            <span class="comment__count">+{{ repliesCount }}</span>
          </div>
        </a>
      </div>
    </td>
    <td data-label="Reviewed">
      <span v-if="!evidence.reviewed && !$groupUser().can('review_evidence')">
        No
      </span>
      <a
        v-if="!evidence.reviewed && $groupUser().can('review_evidence')"
        href="#"
        class="status--needs-review"
        @click.stop.prevent="viewEvidenceModal"
      >
        Needs Review
      </a>
      <span v-if="evidence.reviewed">
        Reviewed {{ evidence.reviewed_at | formatDate('M/D/YY') }}
      </span>
    </td>
  </tr>
</template>

<script>
import ProjectIcon from '../../../icons/ProjectIcon.vue';

export default {
  name: 'GroupWorkTableRow',
  components: { ProjectIcon },
  props: {
    evidence: {
      type: Object,
      required: true,
    },
    reloadFn: {
      type: Function,
      required: true,
    },
    currentIndex: {
      type: Number,
      required: false,
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.users.current;
    },
    repliesCount() {
      return this.evidence.comments.reduce((count, comment) => count + 1 + comment.replies.length, 0);
    },
  },
  methods: {
    viewEvidenceModal() {

      this.$store.dispatch('setcurrentModalIndex', this.currentIndex);

      bus.$emit('open-project-review-modal', {
        isEditing: false,
        groupId: this.evidence.group_id,
        evidenceId: this.evidence.id,
        closeFn: this.reloadFn ? this.reloadFn : null
      });
    },
    launchComments() {
      bus.$emit('open-project-review-modal', {
        isEditing: false,
        groupId: this.evidence.group_id,
        evidenceId: this.evidence.id,
        openDiscussion: true,
        closeFn: this.reloadFn ? this.reloadFn : null,
      });
    },
  },
};
</script>

<style scoped>

</style>