<template>
  <div class="work-area">
    <div class="tab-content__header project-evidence-header">
      <h2 class="project-header__title">
        This work was submitted for: <span class="label label--dark-bg">{{ evidence.title }}</span>
      </h2>

      <div class="project-evidence-header__group">
        <span>Group:</span> <span class="label label--dark-bg">{{ evidence.group.name }} <span v-if="evidence.group.status === 'archived'">({{ evidence.group.status }})</span></span>
      </div>

      <div class="project-evidence-header__users">
        <div>Creators:</div>
        <div
          class="project-evidence-header__users-list"
          :class="{expanded: showingAllUsers || !hasAdditionalCreators}"
        >
          <a
            href="#"
            class="project-evidence-user"
            @click.stop.prevent="openProfile(evidence.user.token)"
          >
            <div
              class="project-evidence-user__avatar"
              v-html="evidence.user.avatar"
            />
            <div class="project-evidence-user__name">
              <span>{{ evidence.user.username }}</span>
            </div>
          </a>

          <a
            v-for="collaborator in evidence.collaborators.data"
            :key="collaborator.id"
            href="#"
            class="project-evidence-user"
            @click.stop.prevent="openProfile(collaborator.token)"
          >
            <div
              class="project-evidence-user__avatar"
              v-html="collaborator.avatar"
            />
            <div class="project-evidence-user__name">
              <span>{{ collaborator.username }}</span>
            </div>
          </a>
        </div>
        <div
          v-if="hasAdditionalCreators"
          class="project-evidence-creator-display-toggle"
          @click.stop.prevent="toggleViewingAllUsers"
        >
          <div v-if="!showingAllUsers">
            <span>View all Creators</span> <i class="fa fa-plus" />
          </div>
          <div v-if="showingAllUsers">
            <span>View fewer Creators</span> <i class="fa fa-minus" />
          </div>
        </div>
      </div>
      <div
        v-if="prompts.length > 0"
        class="project-evidence__prompts"
      >
        <div class="highlight__title">
          <h2>Requirements</h2>
          <span>These required tasks should be reflected in your project work.</span>
        </div>

        <div
          v-for="prompt in prompts"
          :key="prompt.id"
          class="prompt__row"
        >
          <div class="prompt__step">
            <router-link
              :to="promptStep(prompt.order)"
              target="_blank"
              class="button button-step"
            >
              Step {{ prompt.order }}
            </router-link>
          </div>
          <div
            class="prompt__description"
            v-html="prompt.prompt"
          />
        </div>
      </div>
    </div>

    <div
      v-if="evidence.assets.data.length === 0"
      class="work-area__content"
    >
      <p>This project was submitted without any evidence. Note: Some projects do not require any evidence!</p>
    </div>

    <div
      v-else
      class="work-area__content evidence-review"
    >
      <div
        v-for="asset in evidence.assets.data"
        :key="asset.id"
        class="evidence-asset"
        :class="assetClass(asset)"
      >
        <div
          v-if="asset.upload"
          class="evidence-review__file"
        >
          <div
            v-if="asset.type && asset.type.name === 'Image'"
            v-html="asset.upload.path"
          />
          <a
            v-if="asset.type && asset.type.name === 'File'"
            :href="asset.download_url"
            class="evidence-asset--file---box"
          >
            <div class="file-icon">
              <i :class="assetIcon(asset)" />
            </div>
            <div class="file-info">
              <span class="file-name">Download File</span><br>
              <span class="file-size">{{ asset.upload.size | fileSize }}</span>
            </div>
          </a>
        </div>
        <div v-else-if="asset.type && asset.type.name === 'Text'">
          {{ asset.content }}
        </div>
      </div>
    </div>

    <div
      v-if="canExport() && evidence.assets.data.length > 0"
      class="evidence-review__export"
    >
      <a
        class="button"
        href="#"
        @click.stop.prevent="downloadAssets"
      >
        <span class="evidence-review__export-text">Export All</span>
        <span class="evidence-review__export-arrow">
          <svg
            width="15px"
            height="15px"
            viewBox="0 0 15 15"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Arrow</title>
            <desc>Arrow for export all.</desc>
            <defs />
            <g
              id="Page-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="My-Work-Modal_export-button---BOTTOM"
                transform="translate(-758.000000, -1333.000000)"
                fill="#FFFFFF"
              >
                <g
                  id="Modal"
                  transform="translate(290.000000, 55.000000)"
                >
                  <g
                    id="Export-all-/-mark-reviewed---stacked"
                    transform="translate(0.000000, 1265.000000)"
                  >
                    <g
                      id="export-all-as-btn"
                      transform="translate(352.000000, 0.000000)"
                    >
                      <g
                        id="arrow465"
                        transform="translate(111.000000, 7.000000)"
                      >
                        <path
                          id="Shape"
                          d="M21.0877898,11.6661377 L13.3030333,3.97748939 C13.0002382,3.67828748 12.6374123,3.52868653 12.2148494,3.52868653 C11.8002123,3.52868653 11.4414962,3.67828748 11.1385543,3.97748939 L10.241764,4.86320823 C9.93882202,5.14675423 9.78735105,5.50104176 9.78735105,5.9262158 C9.78735105,6.35138983 9.93882202,6.70567737 10.241764,6.98922337 L13.7455575,10.4615021 L5.32688331,10.4615021 C4.91239299,10.4615021 4.57554621,10.6090736 4.31648976,10.9043616 C4.05743331,11.1996495 3.9278317,11.5559665 3.9278317,11.9731676 L3.9278317,13.484978 C3.9278317,13.9023241 4.05743331,14.2584962 4.31648976,14.5537841 C4.57554621,14.849072 4.91239299,14.9966435 5.32688331,14.9966435 L13.7454107,14.9966435 L10.2416172,18.4573253 C9.93867525,18.7562373 9.78720428,19.1145838 9.78720428,19.5319299 C9.78720428,19.9492759 9.93867525,20.3076224 10.2416172,20.6066794 L11.1384075,21.4923982 C11.449422,21.7837722 11.8081382,21.9294591 12.2147027,21.9294591 C12.6293398,21.9294591 12.9921656,21.7837722 13.3028865,21.4923982 L21.087643,13.8037498 C21.3826591,13.5123759 21.530314,13.1543193 21.530314,12.7290003 C21.530314,12.2959984 21.3826591,11.9415659 21.0877898,11.6661377 L21.0877898,11.6661377 Z"
                          transform="translate(12.729073, 12.729073) rotate(-45.000000) translate(-12.729073, -12.729073) "
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </span>
      </a>
    </div>

    <div
      v-if="evidence.activeUserIsEducatorInGroup && evidence.group.status !== 'archived'"
      class="evidence-review__review-actions"
    >
      <a
        v-if="!hasBeenReviewed"
        class="button"
        href="#"
        @click.stop.prevent="markReviewed"
      >
        Mark as Reviewed
      </a>
      <a
        v-if="hasBeenReviewed"
        class="button"
        @click.stop.prevent=""
      >
        <i class="fa fa-check" /> Reviewed
      </a>
      
      <div v-if="evidenceIdArray.length > 0" v-bind:class="{ 'vue-modal__pagination-single': currentIndex <= 0, 'vue-modal__pagination': currentIndex > 0 }">
        <a v-if="currentIndex > 0" href="#" @click.stop.prevent="previous" class="step-pagination step-pagination__previous">
          <svg data-v-5c7b53ac="" height="15px" viewBox="0 0 10 15" width="10px" xmlns="http://www.w3.org/2000/svg" class="pagination__arrow"><defs data-v-5c7b53ac=""></defs> <g data-v-5c7b53ac="" id="Designs" fill="none" fill-rule="evenodd" stroke="none" stroke-width="1"><g data-v-5c7b53ac="" id="Steps-Tab---1-Prompt_no-work-yet" transform="translate(-77.000000, -260.000000)" class="pagination__arrow"><g data-v-5c7b53ac="" id="Project-Detail-Copy-2" transform="translate(49.000000, 251.000000)"><g data-v-5c7b53ac="" id="prev/next-step-top" transform="translate(18.000000, 0.000000)"><g data-v-5c7b53ac="" id="left-arrow"><path data-v-5c7b53ac="" id="Shape-Copy" d="M15.8054327,12.0950972 C15.5563791,11.8539213 15.2655616,11.7333333 14.9333333,11.7333333 C14.5946346,11.7333333 14.3071112,11.8539213 14.0706456,12.0950972 L7.83089431,18.4592607 C7.58807588,18.706796 7.46666667,19.0034063 7.46666667,19.3488518 C7.46666667,19.7007766 7.58819353,19.9940273 7.83089431,20.2286038 L8.54970274,20.9617305 C8.77981555,21.2093857 9.06733892,21.3333333 9.4123905,21.3333333 C9.75744207,21.3333333 10.0449654,21.2092657 10.2750783,20.9617305 L14.9333333,16.2204046 L19.5915884,20.9616105 C19.8215836,21.2091458 20.109107,21.3332133 20.4543938,21.3332133 C20.7928573,21.3332133 21.0837924,21.2091458 21.3264931,20.9616105 L22.0451839,20.2284838 C22.2816495,19.9873079 22.4,19.6940573 22.4,19.3487318 C22.4,19.0032863 22.2785908,18.706676 22.0356547,18.4592607 L15.8054327,12.0950972 Z" transform="translate(14.933333, 16.533333) rotate(-90.000000) translate(-14.933333, -16.533333) "></path></g></g></g></g></g></svg>
        </a>
        <a v-if="currentIndex < (evidenceIdArray.length - 1)" href="#" @click.stop.prevent="next" class="step-pagination step-pagination__next">
          <svg data-v-64ea1eea="" height="15px" viewBox="0 0 10 15" width="10px" xmlns="http://www.w3.org/2000/svg"><defs data-v-64ea1eea=""></defs> <g data-v-64ea1eea="" id="Designs" fill="none" fill-rule="evenodd" stroke="none" stroke-width="1"><g data-v-64ea1eea="" id="Steps-Tab---1-Prompt_no-work-yet" transform="translate(-770.000000, -259.000000)" class="pagination__arrow"><g data-v-64ea1eea="" id="Project-Detail-Copy-2" transform="translate(49.000000, 251.000000)"><g data-v-64ea1eea="" id="prev/next-step-top" transform="translate(18.000000, 0.000000)"><g data-v-64ea1eea="" id="right-arrow" transform="translate(691.000000, 0.000000)"><path data-v-64ea1eea="" id="Shape-Copy-2" d="M23.648667,17.3925941 L17.6409529,11.0284305 C17.4007941,10.7872546 17.120363,10.6666667 16.8,10.6666667 C16.4733976,10.6666667 16.1961429,10.7872546 15.9681225,11.0284305 L9.95121951,17.3925941 C9.71707317,17.6401293 9.6,17.9367397 9.6,18.2821851 C9.6,18.6341099 9.71718661,18.9273606 9.95121951,19.1619371 L10.6443562,19.8950638 C10.8662507,20.1427191 11.1435054,20.2666667 11.4762337,20.2666667 C11.808962,20.2666667 12.0862167,20.1425991 12.3081112,19.8950638 L16.8,15.153738 L21.2918888,19.8949438 C21.5136699,20.1424791 21.7909246,20.2665467 22.1238798,20.2665467 C22.4502552,20.2665467 22.7307998,20.1424791 22.9648327,19.8949438 L23.6578559,19.1618172 C23.8858763,18.9206413 24,18.6273906 24,18.2820651 C24,17.9366197 23.8829268,17.6400093 23.648667,17.3925941 L23.648667,17.3925941 Z" transform="translate(16.800000, 15.466667) scale(-1, 1) rotate(-90.000000) translate(-16.800000, -15.466667) "></path></g></g></g></g></g></svg>
        </a>
      </div>
    </div>

  
  </div>
</template>

<script>

import assetMixin from '../../mixins/asset';

export default {
  name: 'EvidenceReview',
  components: {},
  mixins: [assetMixin],
  props: {
    groupId: {
      type: Number,
      required: true,
    },
    evidence: {
      type: Object,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    isExportable: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      viewableUsers: 0,
      showingAllUsers: false,
      ownerId: null,
      internalReviewed: false,
    };
  },
  computed: {
    hasAdditionalCreators() {
      return this.evidence.collaborators.length + 1 > this.viewableUsers;
    },
    evidenceEndpoint() {
      return laroute.route('group.evidence.update', {
        group: this.groupId,
        evidence: this.evidence.id,
      });
    },
    isOwner() {
      return parseInt(this.currentUser.id, 10) === parseInt(this.evidence.user_id, 10);
    },
    currentUser() {
      return this.$store.state.users.current;
    },
    prompts() {
      return (this.evidence.prompts) ? this.evidence.prompts.data : [];
    },
    hasBeenReviewed() {
      if (this.evidence.reviewed) {
        return true;
      }

      return this.internalReviewed;
    },
    currentIndex() {
      return this.$store.state.evidences.currentModalIndex;
    },
    evidenceIdArray() {
      return this.$store.state.evidences.evidenceIdArray;
    }
  },
  mounted() {
    const $this = this;

    $this.$nextTick($this.viewableCreatorsCount);
    // noinspection JSDeprecatedSymbols
    $(window).resize(_.debounce($this.viewableCreatorsCount));
  },
  methods: {
    downloadAssets() {
      const url = laroute.route('work.export', {
        user: this.currentUser.id,
        evidence: this.evidence.id,
      });

      window.open(url);
    },
    openProfile(token) {
      bus.$emit('modal-clear');
      this.$router.push(`/profile/${token}`);
    },
    canExport() {
      const creators = this.evidence.collaborators.data;
      const ids = creators.map((obj) => obj.id);

      return this.evidence.owner || ids.indexOf(this.currentUser.id) > -1;
    },
    markReviewed() {
      axios.put(this.evidenceEndpoint, {
        reviewed: true,
      }).then(() => {
        $('body').trigger('evidenceReviewed', this.evidence);
        this.internalReviewed = true;

        bus.$emit('evidence-reviewed', {
          evidence: this.evidence,
        });
      });
    },
    toggleViewingAllUsers() {
      this.showingAllUsers = !this.showingAllUsers;
    },
    viewableCreatorsCount() {
      const listWidth = $(this.$el).find('.project-evidence-header__users-list').width();
      const userWidth = $(this.$el).find('.project-evidence-header__users-list .project-evidence-user').outerWidth();
      this.viewableUsers = Math.floor(listWidth / userWidth);
    },
    promptStep(order) {
      return `/project/${this.evidence.project.id}/step/${order}`;
    },
    assetClass(asset) {
      return {
        'evidence-asset--image': asset.type.name === 'Image',
        'evidence-asset--file': asset.type.name === 'File',
        'evidence-asset--text': asset.type.name === 'Text',
      };
    },
    assetIcon(asset) {
      const icon = {};
      icon[asset.type.icon] = true;
      return icon;
    },
    previous() {
      let previousModal = this.currentIndex - 1
      this.internalReviewed = false;
      this.paginateModal(previousModal)
    },
    next() {
      let nextModal = this.currentIndex + 1
      this.internalReviewed = false;
      this.paginateModal(nextModal)
    },
    paginateModal(index) {
      let nextModal = this.currentIndex + 1
      let nextProjectId = this.evidenceIdArray[index]

      this.$store.dispatch('setcurrentModalIndex', index);

      bus.$emit('modal-clear');
      
      bus.$emit('open-project-review-modal', {
        isEditing: false,
        groupId: this.groupId,
        evidenceId: nextProjectId,
      });

      window.scrollTo(0, 0);

    }
  },
};
</script>
