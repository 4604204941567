var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "work-area" }, [
    _c("div", { staticClass: "tab-content__header project-evidence-header" }, [
      _c("h2", { staticClass: "project-header__title" }, [
        _vm._v("\n      This work was submitted for: "),
        _c("span", { staticClass: "label label--dark-bg" }, [
          _vm._v(_vm._s(_vm.evidence.title)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "project-evidence-header__group" }, [
        _c("span", [_vm._v("Group:")]),
        _vm._v(" "),
        _c("span", { staticClass: "label label--dark-bg" }, [
          _vm._v(_vm._s(_vm.evidence.group.name) + " "),
          _vm.evidence.group.status === "archived"
            ? _c("span", [
                _vm._v("(" + _vm._s(_vm.evidence.group.status) + ")"),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "project-evidence-header__users" }, [
        _c("div", [_vm._v("Creators:")]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "project-evidence-header__users-list",
            class: {
              expanded: _vm.showingAllUsers || !_vm.hasAdditionalCreators,
            },
          },
          [
            _c(
              "a",
              {
                staticClass: "project-evidence-user",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.openProfile(_vm.evidence.user.token)
                  },
                },
              },
              [
                _c("div", {
                  staticClass: "project-evidence-user__avatar",
                  domProps: { innerHTML: _vm._s(_vm.evidence.user.avatar) },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "project-evidence-user__name" }, [
                  _c("span", [_vm._v(_vm._s(_vm.evidence.user.username))]),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm._l(_vm.evidence.collaborators.data, function (collaborator) {
              return _c(
                "a",
                {
                  key: collaborator.id,
                  staticClass: "project-evidence-user",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.openProfile(collaborator.token)
                    },
                  },
                },
                [
                  _c("div", {
                    staticClass: "project-evidence-user__avatar",
                    domProps: { innerHTML: _vm._s(collaborator.avatar) },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "project-evidence-user__name" }, [
                    _c("span", [_vm._v(_vm._s(collaborator.username))]),
                  ]),
                ]
              )
            }),
          ],
          2
        ),
        _vm._v(" "),
        _vm.hasAdditionalCreators
          ? _c(
              "div",
              {
                staticClass: "project-evidence-creator-display-toggle",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.toggleViewingAllUsers($event)
                  },
                },
              },
              [
                !_vm.showingAllUsers
                  ? _c("div", [
                      _c("span", [_vm._v("View all Creators")]),
                      _vm._v(" "),
                      _c("i", { staticClass: "fa fa-plus" }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.showingAllUsers
                  ? _c("div", [
                      _c("span", [_vm._v("View fewer Creators")]),
                      _vm._v(" "),
                      _c("i", { staticClass: "fa fa-minus" }),
                    ])
                  : _vm._e(),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.prompts.length > 0
        ? _c(
            "div",
            { staticClass: "project-evidence__prompts" },
            [
              _vm._m(0),
              _vm._v(" "),
              _vm._l(_vm.prompts, function (prompt) {
                return _c(
                  "div",
                  { key: prompt.id, staticClass: "prompt__row" },
                  [
                    _c(
                      "div",
                      { staticClass: "prompt__step" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "button button-step",
                            attrs: {
                              to: _vm.promptStep(prompt.order),
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n            Step " +
                                _vm._s(prompt.order) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", {
                      staticClass: "prompt__description",
                      domProps: { innerHTML: _vm._s(prompt.prompt) },
                    }),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.evidence.assets.data.length === 0
      ? _c("div", { staticClass: "work-area__content" }, [
          _c("p", [
            _vm._v(
              "This project was submitted without any evidence. Note: Some projects do not require any evidence!"
            ),
          ]),
        ])
      : _c(
          "div",
          { staticClass: "work-area__content evidence-review" },
          _vm._l(_vm.evidence.assets.data, function (asset) {
            return _c(
              "div",
              {
                key: asset.id,
                staticClass: "evidence-asset",
                class: _vm.assetClass(asset),
              },
              [
                asset.upload
                  ? _c("div", { staticClass: "evidence-review__file" }, [
                      asset.type && asset.type.name === "Image"
                        ? _c("div", {
                            domProps: { innerHTML: _vm._s(asset.upload.path) },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      asset.type && asset.type.name === "File"
                        ? _c(
                            "a",
                            {
                              staticClass: "evidence-asset--file---box",
                              attrs: { href: asset.download_url },
                            },
                            [
                              _c("div", { staticClass: "file-icon" }, [
                                _c("i", { class: _vm.assetIcon(asset) }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "file-info" }, [
                                _c("span", { staticClass: "file-name" }, [
                                  _vm._v("Download File"),
                                ]),
                                _c("br"),
                                _vm._v(" "),
                                _c("span", { staticClass: "file-size" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("fileSize")(asset.upload.size)
                                    )
                                  ),
                                ]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : asset.type && asset.type.name === "Text"
                  ? _c("div", [
                      _vm._v("\n        " + _vm._s(asset.content) + "\n      "),
                    ])
                  : _vm._e(),
              ]
            )
          }),
          0
        ),
    _vm._v(" "),
    _vm.canExport() && _vm.evidence.assets.data.length > 0
      ? _c("div", { staticClass: "evidence-review__export" }, [
          _c(
            "a",
            {
              staticClass: "button",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.downloadAssets($event)
                },
              },
            },
            [
              _c("span", { staticClass: "evidence-review__export-text" }, [
                _vm._v("Export All"),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "evidence-review__export-arrow" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "15px",
                      height: "15px",
                      viewBox: "0 0 15 15",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("title", [_vm._v("Arrow")]),
                    _vm._v(" "),
                    _c("desc", [_vm._v("Arrow for export all.")]),
                    _vm._v(" "),
                    _c("defs"),
                    _vm._v(" "),
                    _c(
                      "g",
                      {
                        attrs: {
                          id: "Page-1",
                          stroke: "none",
                          "stroke-width": "1",
                          fill: "none",
                          "fill-rule": "evenodd",
                        },
                      },
                      [
                        _c(
                          "g",
                          {
                            attrs: {
                              id: "My-Work-Modal_export-button---BOTTOM",
                              transform: "translate(-758.000000, -1333.000000)",
                              fill: "#FFFFFF",
                            },
                          },
                          [
                            _c(
                              "g",
                              {
                                attrs: {
                                  id: "Modal",
                                  transform: "translate(290.000000, 55.000000)",
                                },
                              },
                              [
                                _c(
                                  "g",
                                  {
                                    attrs: {
                                      id: "Export-all-/-mark-reviewed---stacked",
                                      transform:
                                        "translate(0.000000, 1265.000000)",
                                    },
                                  },
                                  [
                                    _c(
                                      "g",
                                      {
                                        attrs: {
                                          id: "export-all-as-btn",
                                          transform:
                                            "translate(352.000000, 0.000000)",
                                        },
                                      },
                                      [
                                        _c(
                                          "g",
                                          {
                                            attrs: {
                                              id: "arrow465",
                                              transform:
                                                "translate(111.000000, 7.000000)",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                id: "Shape",
                                                d: "M21.0877898,11.6661377 L13.3030333,3.97748939 C13.0002382,3.67828748 12.6374123,3.52868653 12.2148494,3.52868653 C11.8002123,3.52868653 11.4414962,3.67828748 11.1385543,3.97748939 L10.241764,4.86320823 C9.93882202,5.14675423 9.78735105,5.50104176 9.78735105,5.9262158 C9.78735105,6.35138983 9.93882202,6.70567737 10.241764,6.98922337 L13.7455575,10.4615021 L5.32688331,10.4615021 C4.91239299,10.4615021 4.57554621,10.6090736 4.31648976,10.9043616 C4.05743331,11.1996495 3.9278317,11.5559665 3.9278317,11.9731676 L3.9278317,13.484978 C3.9278317,13.9023241 4.05743331,14.2584962 4.31648976,14.5537841 C4.57554621,14.849072 4.91239299,14.9966435 5.32688331,14.9966435 L13.7454107,14.9966435 L10.2416172,18.4573253 C9.93867525,18.7562373 9.78720428,19.1145838 9.78720428,19.5319299 C9.78720428,19.9492759 9.93867525,20.3076224 10.2416172,20.6066794 L11.1384075,21.4923982 C11.449422,21.7837722 11.8081382,21.9294591 12.2147027,21.9294591 C12.6293398,21.9294591 12.9921656,21.7837722 13.3028865,21.4923982 L21.087643,13.8037498 C21.3826591,13.5123759 21.530314,13.1543193 21.530314,12.7290003 C21.530314,12.2959984 21.3826591,11.9415659 21.0877898,11.6661377 L21.0877898,11.6661377 Z",
                                                transform:
                                                  "translate(12.729073, 12.729073) rotate(-45.000000) translate(-12.729073, -12.729073) ",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.evidence.activeUserIsEducatorInGroup &&
    _vm.evidence.group.status !== "archived"
      ? _c("div", { staticClass: "evidence-review__review-actions" }, [
          !_vm.hasBeenReviewed
            ? _c(
                "a",
                {
                  staticClass: "button",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.markReviewed($event)
                    },
                  },
                },
                [_vm._v("\n      Mark as Reviewed\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasBeenReviewed
            ? _c(
                "a",
                {
                  staticClass: "button",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-check" }),
                  _vm._v(" Reviewed\n    "),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.evidenceIdArray.length > 0
            ? _c(
                "div",
                {
                  class: {
                    "vue-modal__pagination-single": _vm.currentIndex <= 0,
                    "vue-modal__pagination": _vm.currentIndex > 0,
                  },
                },
                [
                  _vm.currentIndex > 0
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "step-pagination step-pagination__previous",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.previous($event)
                            },
                          },
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "pagination__arrow",
                              attrs: {
                                "data-v-5c7b53ac": "",
                                height: "15px",
                                viewBox: "0 0 10 15",
                                width: "10px",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("defs", { attrs: { "data-v-5c7b53ac": "" } }),
                              _vm._v(" "),
                              _c(
                                "g",
                                {
                                  attrs: {
                                    "data-v-5c7b53ac": "",
                                    id: "Designs",
                                    fill: "none",
                                    "fill-rule": "evenodd",
                                    stroke: "none",
                                    "stroke-width": "1",
                                  },
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      staticClass: "pagination__arrow",
                                      attrs: {
                                        "data-v-5c7b53ac": "",
                                        id: "Steps-Tab---1-Prompt_no-work-yet",
                                        transform:
                                          "translate(-77.000000, -260.000000)",
                                      },
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            "data-v-5c7b53ac": "",
                                            id: "Project-Detail-Copy-2",
                                            transform:
                                              "translate(49.000000, 251.000000)",
                                          },
                                        },
                                        [
                                          _c(
                                            "g",
                                            {
                                              attrs: {
                                                "data-v-5c7b53ac": "",
                                                id: "prev/next-step-top",
                                                transform:
                                                  "translate(18.000000, 0.000000)",
                                              },
                                            },
                                            [
                                              _c(
                                                "g",
                                                {
                                                  attrs: {
                                                    "data-v-5c7b53ac": "",
                                                    id: "left-arrow",
                                                  },
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      "data-v-5c7b53ac": "",
                                                      id: "Shape-Copy",
                                                      d: "M15.8054327,12.0950972 C15.5563791,11.8539213 15.2655616,11.7333333 14.9333333,11.7333333 C14.5946346,11.7333333 14.3071112,11.8539213 14.0706456,12.0950972 L7.83089431,18.4592607 C7.58807588,18.706796 7.46666667,19.0034063 7.46666667,19.3488518 C7.46666667,19.7007766 7.58819353,19.9940273 7.83089431,20.2286038 L8.54970274,20.9617305 C8.77981555,21.2093857 9.06733892,21.3333333 9.4123905,21.3333333 C9.75744207,21.3333333 10.0449654,21.2092657 10.2750783,20.9617305 L14.9333333,16.2204046 L19.5915884,20.9616105 C19.8215836,21.2091458 20.109107,21.3332133 20.4543938,21.3332133 C20.7928573,21.3332133 21.0837924,21.2091458 21.3264931,20.9616105 L22.0451839,20.2284838 C22.2816495,19.9873079 22.4,19.6940573 22.4,19.3487318 C22.4,19.0032863 22.2785908,18.706676 22.0356547,18.4592607 L15.8054327,12.0950972 Z",
                                                      transform:
                                                        "translate(14.933333, 16.533333) rotate(-90.000000) translate(-14.933333, -16.533333) ",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.currentIndex < _vm.evidenceIdArray.length - 1
                    ? _c(
                        "a",
                        {
                          staticClass: "step-pagination step-pagination__next",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.next($event)
                            },
                          },
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                "data-v-64ea1eea": "",
                                height: "15px",
                                viewBox: "0 0 10 15",
                                width: "10px",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("defs", { attrs: { "data-v-64ea1eea": "" } }),
                              _vm._v(" "),
                              _c(
                                "g",
                                {
                                  attrs: {
                                    "data-v-64ea1eea": "",
                                    id: "Designs",
                                    fill: "none",
                                    "fill-rule": "evenodd",
                                    stroke: "none",
                                    "stroke-width": "1",
                                  },
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      staticClass: "pagination__arrow",
                                      attrs: {
                                        "data-v-64ea1eea": "",
                                        id: "Steps-Tab---1-Prompt_no-work-yet",
                                        transform:
                                          "translate(-770.000000, -259.000000)",
                                      },
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            "data-v-64ea1eea": "",
                                            id: "Project-Detail-Copy-2",
                                            transform:
                                              "translate(49.000000, 251.000000)",
                                          },
                                        },
                                        [
                                          _c(
                                            "g",
                                            {
                                              attrs: {
                                                "data-v-64ea1eea": "",
                                                id: "prev/next-step-top",
                                                transform:
                                                  "translate(18.000000, 0.000000)",
                                              },
                                            },
                                            [
                                              _c(
                                                "g",
                                                {
                                                  attrs: {
                                                    "data-v-64ea1eea": "",
                                                    id: "right-arrow",
                                                    transform:
                                                      "translate(691.000000, 0.000000)",
                                                  },
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      "data-v-64ea1eea": "",
                                                      id: "Shape-Copy-2",
                                                      d: "M23.648667,17.3925941 L17.6409529,11.0284305 C17.4007941,10.7872546 17.120363,10.6666667 16.8,10.6666667 C16.4733976,10.6666667 16.1961429,10.7872546 15.9681225,11.0284305 L9.95121951,17.3925941 C9.71707317,17.6401293 9.6,17.9367397 9.6,18.2821851 C9.6,18.6341099 9.71718661,18.9273606 9.95121951,19.1619371 L10.6443562,19.8950638 C10.8662507,20.1427191 11.1435054,20.2666667 11.4762337,20.2666667 C11.808962,20.2666667 12.0862167,20.1425991 12.3081112,19.8950638 L16.8,15.153738 L21.2918888,19.8949438 C21.5136699,20.1424791 21.7909246,20.2665467 22.1238798,20.2665467 C22.4502552,20.2665467 22.7307998,20.1424791 22.9648327,19.8949438 L23.6578559,19.1618172 C23.8858763,18.9206413 24,18.6273906 24,18.2820651 C24,17.9366197 23.8829268,17.6400093 23.648667,17.3925941 L23.648667,17.3925941 Z",
                                                      transform:
                                                        "translate(16.800000, 15.466667) scale(-1, 1) rotate(-90.000000) translate(-16.800000, -15.466667) ",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "highlight__title" }, [
      _c("h2", [_vm._v("Requirements")]),
      _vm._v(" "),
      _c("span", [
        _vm._v(
          "These required tasks should be reflected in your project work."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }